import React, { useRef } from "react";
import { createTrackingEvent } from "lib/tracking";
import PropTypes from "prop-types";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as AccordionStyling from "./AccordionSection.module.scss";
import { graphql } from "gatsby";

const Accordion = ({ title, text, active, onToggle }) => {
  const textWrapper = useRef();

  return (
    <li
      className={`${AccordionStyling.accordion} ${
        active ? AccordionStyling.active : ""
      }`}
    >
      <button
        className={`flex r-top ${AccordionStyling.titleBtn}`}
        onClick={() => {
          onToggle();

          !active &&
            createTrackingEvent(
              "Storefront - Content Accordion Click",
              {
                "Accordion Click Name": title,
              },
              "Click",
              {
                "Accordion Click Name": title,
              },
              title
            );
        }}
      >
        <span className={AccordionStyling.titleText}>{title}</span>
        <div
          className={
            active
              ? `${AccordionStyling.plusMinus} ${AccordionStyling.clicked}`
              : `${AccordionStyling.plusMinus}`
          }
        ></div>
      </button>
      <div
        className={`c-10 ${AccordionStyling.textWrapper}`}
        ref={textWrapper}
        style={
          active
            ? { height: textWrapper.current.scrollHeight, marginBottom: "6rem" }
            : { height: "0px", marginBottom: "0" }
        }
      >
        <StructuredTextRenderer
          data={text}
          isParagraphClassName="mb-5"
          isHeadingClassName="mb-5"
        />
      </div>
    </li>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export const query = graphql`
  fragment Accordion on DatoCmsAccordion {
    id: originalId
    title
    accordionText {
      value
    }
  }
`;
