// extracted by mini-css-extract-plugin
export var AccordionImageSection = "AccordionSection-module--AccordionImageSection--735f5";
export var accordion = "AccordionSection-module--accordion--f0b05";
export var accordionList = "AccordionSection-module--accordionList--56a5a";
export var accordionSection = "AccordionSection-module--accordionSection--59221";
export var active = "AccordionSection-module--active--4c833";
export var clicked = "AccordionSection-module--clicked--53ed8";
export var plusMinus = "AccordionSection-module--plusMinus--0cd06";
export var subtitle = "AccordionSection-module--subtitle--3ab68";
export var textWrapper = "AccordionSection-module--textWrapper--626c5";
export var title = "AccordionSection-module--title--bc57b";
export var titleBtn = "AccordionSection-module--titleBtn--37cb7";
export var titleText = "AccordionSection-module--titleText--a9514";