import React, { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "./Accordion";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as AccordionStyling from "./AccordionSection.module.scss";
import { graphql } from "gatsby";

const AccordionSection = ({ accordionSection }) => {
  const title = accordionSection.title;
  const introText = accordionSection.introText;
  const accordions = accordionSection.accordions;

  const [activeAccordionId, setActiveAccordionId] = useState(null);

  const handleToggle = (index) => {
    if (activeAccordionId === index) {
      return setActiveAccordionId(null);
    }
    setActiveAccordionId(index);
  };

  return (
    <div className={AccordionStyling.accordionSection}>
      <h2 className={`mb-5 ${AccordionStyling.title}`}>{title}</h2>
      {introText.value && (
        <div className={`mono-container ${AccordionStyling.subtitle}`}>
          <StructuredTextRenderer data={introText} />
        </div>
      )}
      <ul className={AccordionStyling.accordionList}>
        {accordions.map((accordion) => {
          return (
            <Accordion
              key={accordion.id}
              title={accordion.title}
              text={accordion.accordionText}
              onToggle={() => handleToggle(accordion.id)}
              active={activeAccordionId === accordion.id}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default AccordionSection;

AccordionSection.propTypes = {
  accordionSection: PropTypes.shape({
    title: PropTypes.string,
    introText: PropTypes.object,
    accordions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        accordionText: PropTypes.object.isRequired,
      })
    ),
  }),
};

export const query = graphql`
  fragment AccordionSection on DatoCmsAccordionsection {
    id: originalId
    title
    introText {
      value
    }
    accordions {
      ...Accordion
    }
  }
`