import React from "react";
import PropTypes from "prop-types";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import AccordionSection from "./AccordionSection";
import { GatsbyImage } from "gatsby-plugin-image";
import * as AccordionStyling from "./AccordionSection.module.scss";
import { graphql } from "gatsby";

const AccordionImage = ({ block }) => {
  const headline = block.title;
  const accordionSections = block.accordionsections;
  const image = block.image;
  const hideImgOnMobile = block.hideImageOnMobile;

  return (
    <Section
      className={`accordionModule mono-container ${AccordionStyling.AccordionImageSection}`}
    >
      <p className="text7">
        {headline}
      </p>
      <div className="row r-top">
        <div className="col l-6 sm-12">
          {accordionSections.map((accordionSection) => {
            return (
              <AccordionSection
                key={accordionSection.id}
                accordionSection={accordionSection}
              />
            );
          })}
        </div>
        <div className="col l-1 sm-12"></div>
        <div
          className={
            hideImgOnMobile ? "col l-5 sm-12 hide-to-m" : "col l-5 sm-12"
          }
        >
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        </div>
      </div>
    </Section>
  );
};

export default AccordionImage;

AccordionImage.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    accordionsections: PropTypes.array.isRequired,
  }),
};

export const query = graphql`
  fragment AccordionImage on DatoCmsAccordionimage {
    model {
      apiKey
    }
    id: originalId
    title
    hideImageOnMobile
    image {
      alt
      gatsbyImageData
    }
    accordionsections {
      ...AccordionSection
    }
  }
`;
